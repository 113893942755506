import { platformApi } from "./axiosConfig"
import * as Constants from "../../constants/apis"

export const UserAPI = {
  getStats: async function () {
    const response = await platformApi.request({
      url: Constants.GET_USER_STATS,
      method: Constants.GET,
    })

    return response.data
  },
  certifyAll: async function (service: string) {
    const response = await platformApi.request({
      url: Constants.CERTIFY_ALL,
      method: Constants.PATCH,
      headers: { "Comixtime-Service": service },
    })

    return response.data
  },
}
