import * as Constants from "../../constants/apis"
import { ComicIsUnique } from "../../types/isUnique/ComicIsUnique"
import { platformApi } from "./axiosConfig"
import { generatePath } from "react-router-dom"
import { ComicUpdateData } from "../../types/entity/comic/ComicUpdateData"
import { Filter } from "../../types/dashboard/Filter"
import { EnvConstants } from "../../constants/envs"

export const ComicsAPI = {
  getById: async function (idComic: number, service: string) {
    const response = await platformApi.request({
      url: generatePath(Constants.COMICS_GET_BY_ID_API_PATH, {
        id: idComic.toString(),
      }),
      method: Constants.GET,
      headers: { "Comixtime-Service": service },
    })

    return response.data
  },
  getPaginated: async function (filter: Filter, controller: AbortController, service: string) {
    const response = await platformApi.request({
      url: Constants.GET_COMICS_PAGINATED,
      method: Constants.POST,
      data: filter,
      signal: controller.signal,
      headers: { "Comixtime-Service": service },
    })

    return response.data
  },
  getPaginatedExplore: async function (filter: Filter, controller: AbortController) {
    const authToken =
      EnvConstants.ENV !== EnvConstants.PRODUCTION
        ? "$2y$10$G.NMJTvGw9tdHcqKzpbORehAbOEpp.sBwSn7ZdrpnYqj4mHsLYnPG"
        : "$2y$10$yymDwkxFY42O/qpY6bAn5e67MSAhB29g49AdnM4mbmGvKY4ThMalC"
    const response = await platformApi.request({
      url: Constants.GET_COMICS_PAGINATED,
      method: Constants.POST,
      data: filter,
      signal: controller.signal,
      headers: {
        "Comixtime-Service": "DEX",
        authToken: authToken,
      },
    })

    return response.data
  },
  create: async function (comic: ComicUpdateData, service: string) {
    const response = await platformApi.request({
      url: Constants.COMIC_PATH,
      method: Constants.POST,
      data: comic,
      headers: { "Comixtime-Service": service },
    })

    return response.data
  },
  update: async function (id: number, comic: ComicUpdateData, service: string) {
    return await platformApi.request({
      url: generatePath(Constants.COMICS_GET_BY_ID_API_PATH, {
        id: id.toString(),
      }),
      method: Constants.PUT,
      data: comic,
      headers: { "Comixtime-Service": service },
    })
  },
  delete: async function (id: number, service: string) {
    return await platformApi.request({
      url: generatePath(Constants.COMICS_GET_BY_ID_API_PATH, {
        id: id.toString(),
      }),
      method: Constants.DELETE,
      headers: { "Comixtime-Service": service },
    })
  },
  getData: async function (url: string, service: string) {
    const response = await platformApi.request({
      url: Constants.GET_COMIC_DATA,
      method: Constants.GET,
      headers: { "Comixtime-Service": service },
      params: {
        url: url,
      },
    })

    return response.data
  },
  isUnique: async function (
    title: string,
    variant: boolean | null,
    service: string,
    idSeries?: number,
    number?: number,
    id?: number,
  ): Promise<boolean> {
    const params: ComicIsUnique = {
      title: title,
    }
    if (variant !== null) params.variant = variant
    if (number !== undefined) params.number = number
    if (idSeries !== undefined) params.id_series = idSeries
    if (id !== undefined) params.excluded = id
    const response = await platformApi.request({
      url: Constants.IS_UNIQUE_COMIC_PATH,
      method: Constants.GET,
      headers: { "Comixtime-Service": service },
      params: params,
    })

    return response.data
  },
  certify: async function (idComic: number, service: string) {
    const response = await platformApi.request({
      url: Constants.CERTIFY_COMIC,
      method: Constants.PATCH,
      headers: { "Comixtime-Service": service },
      params: { id: idComic },
    })

    return response.data
  },
  certifyAll: async function (service: string) {
    const response = await platformApi.request({
      url: Constants.CERTIFY_ALL_COMICS,
      method: Constants.PATCH,
      headers: { "Comixtime-Service": service },
    })

    return response.data
  },
  updateState: async function (idComic: number, state: number, service: string) {
    return await platformApi.request({
      url: Constants.COMIC_UPDATE_STATE,
      data: {
        state,
      },
      method: Constants.PATCH,
      headers: { "Comixtime-Service": service, "Content-Type": "application/json; charset=utf-8" },
      params: {
        id: idComic,
      },
    })
  },
}
