import React from "react"

const HomePage = React.lazy(() => import("frontend/views/HomePage"))
const Comics = React.lazy(() => import("frontend/views/Comics"))
const Series = React.lazy(() => import("frontend/views/Series"))
const Comicsets = React.lazy(() => import("frontend/views/Comicsets"))
const Authors = React.lazy(() => import("frontend/views/Authors"))
const ComicInfo = React.lazy(() => import("frontend/views/InfoPages/ComicInfo"))
const SeriesInfo = React.lazy(() => import("frontend/views/InfoPages/SeriesInfo"))
const ComicsetInfo = React.lazy(() => import("frontend/views/InfoPages/ComicsetInfo"))
const UpdateComic = React.lazy(() => import("frontend/views/Forms/UpdateComic"))
const CreateComic = React.lazy(() => import("frontend/views/Forms/CreateComic"))
const DuplicateComic = React.lazy(() => import("frontend/views/Forms/DuplicateComic"))
const CreateComicVariant = React.lazy(() => import("frontend/views/Forms/CreateComicVariant"))
const CreateSeries = React.lazy(() => import("frontend/views/Forms/CreateSeries"))
const UpdateSeries = React.lazy(() => import("frontend/views/Forms/UpdateSeries"))
const CreateComicset = React.lazy(() => import("frontend/views/Forms/CreateComicset"))
const UpdateComicset = React.lazy(() => import("frontend/views/Forms/UpdateComicset"))
const CreateAuthor = React.lazy(() => import("frontend/views/Forms/CreateAuthor"))
const UpdateAuthor = React.lazy(() => import("frontend/views/Forms/UpdateAuthor"))

export const pubRoutes = [
  { path: "/", component: HomePage },
  { path: "/firstaccess", component: HomePage },
  { path: "/pub", component: HomePage },
  { path: "/pub/comics", component: Comics },
  { path: "/pub/comics/create", component: CreateComic },
  { path: "/pub/comics/:id", component: ComicInfo },
  { path: "/pub/comics/:id/update", component: UpdateComic },
  { path: "/pub/comics/:id/duplicate", component: DuplicateComic },
  { path: "/pub/comics/:id/variant", component: CreateComicVariant },
  { path: "/pub/series", component: Series },
  { path: "/pub/series/create", component: CreateSeries },
  { path: "/pub/series/:id", component: SeriesInfo },
  { path: "/pub/series/:id/update", component: UpdateSeries },
  { path: "/pub/comicsets", component: Comicsets },
  { path: "/pub/comicsets/create", component: CreateComicset },
  { path: "/pub/comicsets/:id", component: ComicsetInfo },
  { path: "/pub/comicsets/:id/update", component: UpdateComicset },
  { path: "/pub/:entity/:id/comics", component: Comics },
  { path: "/pub/:entity/:id/comicsets", component: Comicsets },
  { path: "/pub/:entity/:id/comics/create", component: CreateComic },
  { path: "/pub/:entity/:id/comicsets/create", component: CreateComicset },
]

export const dexRoutes = [
  { path: "/dex", component: HomePage },
  { path: "/dex/comics", component: Comics },
  { path: "/dex/comics/create", component: CreateComic },
  { path: "/dex/comics/:id", component: ComicInfo },
  { path: "/dex/comics/:id/update", component: UpdateComic },
  { path: "/dex/comics/:id/duplicate", component: DuplicateComic },
  { path: "/dex/comics/:id/variant", component: CreateComicVariant },
  { path: "/dex/series", component: Series },
  { path: "/dex/series/create", component: CreateSeries },
  { path: "/dex/series/:id", component: SeriesInfo },
  { path: "/dex/series/:id/update", component: UpdateSeries },
  { path: "/dex/comicsets", component: Comicsets },
  { path: "/dex/comicsets/create", component: CreateComicset },
  { path: "/dex/comicsets/:id", component: ComicsetInfo },
  { path: "/dex/comicsets/:id/update", component: UpdateComicset },
  { path: "/dex/:entity/:id/comics", component: Comics },
  { path: "/dex/:entity/:id/comicsets", component: Comicsets },
  { path: "/dex/:entity/:id/comics/create", component: CreateComic },
  { path: "/dex/:entity/:id/comicsets/create", component: CreateComicset },
  { path: "/dex/authors", component: Authors },
  { path: "/dex/authors/create", component: CreateAuthor },
  { path: "/dex/authors/:id/update", component: UpdateAuthor },
]
