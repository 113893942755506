import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDatabase, faBook, faAddressBook, faBinoculars } from "@fortawesome/free-solid-svg-icons"
import i18n from "frontend/languages/index"
import { TranslationConstants } from "frontend/constants/translations"
import { BookFilled, TeamOutlined } from "@ant-design/icons"

type NavigationElement = {
  path: string
  name: string
  disabled?: boolean
  className?: string
  icon: React.ReactNode
  paths?: NavigationElement[]
}

const navigation: NavigationElement[] = [
  {
    name: "My Comics",
    path: "/pub",
    icon: <FontAwesomeIcon icon={faAddressBook} />,
    paths: [
      {
        path: "/comics",
        name: i18n.t(TranslationConstants.i18n_COMICS),
        icon: <FontAwesomeIcon icon={faBook} />,
      },
      {
        path: "/series",
        name: i18n.t(TranslationConstants.i18n_SERIES),
        icon: <FontAwesomeIcon icon={faBinoculars} />,
      },
      {
        path: "/comicsets",
        name: i18n.t(TranslationConstants.i18n_COMICSETS),
        icon: <BookFilled />,
      },
    ],
  },
  {
    name: "Dex",
    path: "/dex",
    icon: <FontAwesomeIcon icon={faDatabase} />,
    paths: [
      {
        path: "/comics",
        name: i18n.t(TranslationConstants.i18n_COMICS),
        icon: <FontAwesomeIcon icon={faBook} />,
      },
      {
        path: "/series",
        name: i18n.t(TranslationConstants.i18n_SERIES),
        icon: <FontAwesomeIcon icon={faBinoculars} />,
      },
      {
        path: "/comicsets",
        name: i18n.t(TranslationConstants.i18n_COMICSETS),
        icon: <BookFilled />,
      },
      {
        path: "/authors",
        name: i18n.t(TranslationConstants.i18n_AUTHORS),
        icon: <TeamOutlined />,
      },
    ],
  },
]

export default navigation
