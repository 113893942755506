import { AuthContextData } from "../../../types/user/AuthContextData"
import { Button, Dropdown, Menu, notification } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { AccessAPI } from "../../../api/common/AccessAPI"
import i18n from "i18next"
import { TranslationConstants } from "../../../constants/translations"
import "./ProfileDropdown.scss"

const ProfileDropdown = (user: AuthContextData) => {
  function handleLogout() {
    AccessAPI.logout()
      .then(() => {
        ;("OK - Logout")
        window.location.reload()
      })
      .catch((error: string) => {
        error
        notification.error({
          message: i18n.t(TranslationConstants.i18n_SOMETHING_WENT_WRONG),
          duration: 4,
        })
      })
  }

  // TODO: implement profile page
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link to={"/profile"}>
              <FontAwesomeIcon icon={faUser} size={"lg"} /> Profile{" "}
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Button className={"logout-btn"} type={"primary"} danger onClick={handleLogout}>
              <FontAwesomeIcon icon={faPowerOff} size={"lg"} /> Logout{" "}
            </Button>
          ),
        },
      ]}
    />
  )
  return (
    <>
      <Dropdown className={"user-dropdown"} overlay={menu} placement={"bottomLeft"} arrow={{ pointAtCenter: true }}>
        <Button type={"primary"} size={"large"}>
          <FontAwesomeIcon icon={faUser} className={"me-2"} size={"lg"} />
        </Button>
      </Dropdown>
    </>
  )
}

export default ProfileDropdown
