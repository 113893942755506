import React, { useEffect, useState } from "react"
import "./App.scss"
import "bootstrap/dist/css/bootstrap.css"
import { Route, Routes } from "react-router-dom"
import { Page404 } from "./views/404/404"
import "./languages/index"
import Login from "./views/Login"
import AuthContext from "./context/auth/AuthContext"
import { AuthContextData } from "./types/user/AuthContextData"
import { AccessAPI } from "./api/common/AccessAPI"
import { ComixtimeContainer } from "./components/Layout/ComixtimeContainer/ComixtimeContainer"
import HomePage from "./views/HomePage"
import { dexRoutes, pubRoutes } from "./routes"
import ComicsExplore from "./views/ComicsExplore"
import { Spin } from "antd"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { useLocation } from "react-router"
import Consents from "./views/Consents"

const loadingFn = () => (
  <div className="animated fadeIn pt-5 justify-content-center align-content-center">
    <Spin spinning={true} />
  </div>
)

export function App() {
  const queryClient = new QueryClient()

  const [loading, setLoading] = useState<boolean>(true)
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const [firstAccess, setFirstAccess] = useState<boolean>(false)
  const [consents, setConsents] = useState<boolean>(false)
  const [explore, setExplore] = useState<boolean>(false)

  const [authContext, setAuthContext] = useState<AuthContextData | undefined>(undefined)

  const location = useLocation()

  useEffect(() => {
    document.title = "Platform"
    setFirstAccess(false)
    setConsents(false)
    if (location.pathname == "/firstaccess") setFirstAccess(true)
    else if (location.pathname == "/consents") setConsents(true)
    setLoading(true)
    AccessAPI.verifyToken()
      .then((response) => {
        setAuthContext(response.data)
        setIsLogged(true)
      })
      .catch(() => {
        setIsLogged(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  let page = <>{loadingFn()}</>

  if (!loading) {
    if (!isLogged) {
      if (consents) page = <Consents checkbox={false} />
      else if (explore) page = <ComicsExplore onExplore={setExplore} />
      else page = <Login onExplore={setExplore} firstAccess={firstAccess} />
    } else {
      if (authContext?.role.includes(8) && authContext?.pub_consents_compiled != true) {
        page = <Consents checkbox={true} />
      } else {
        page = (
          <QueryClientProvider client={queryClient}>
            <AuthContext.Provider value={authContext}>
              <ComixtimeContainer>
                <Routes>
                  <Route path={"/"} element={<HomePage />} />
                  {authContext?.role.includes(8) &&
                    pubRoutes.map((route, i) => {
                      return route.component ? <Route key={i} path={route.path} element={<route.component />} /> : null
                    })}
                  {dexRoutes.map((route, i) => {
                    return route.component ? <Route key={i} path={route.path} element={<route.component />} /> : null
                  })}
                  <Route path={"*"} element={<Page404 />} />
                </Routes>
              </ComixtimeContainer>
            </AuthContext.Provider>
          </QueryClientProvider>
        )
      }
    }
  }

  return <React.Suspense fallback={loadingFn()}>{page}</React.Suspense>
}
